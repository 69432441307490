import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getCookieTypes, getCookieAccepted, setCookieTypes, updateCookies } from './services'
import { Modal, Flex, Image, Box, Copy, Button } from '@elparking/components'
import { FormattedMessage } from 'react-intl'
import { TRADENAME } from 'commons/js/constants'
import CookiesSelector from './CookiesSelector'
import { getCookies } from 'commons/js/api'
import { useApi } from '@elparking/utils'
import { AnalyticsContext } from '@elparking/analytics'

export const CookiesContainer = styled(Flex)`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
`

export const CookiesContent = styled(Flex)`
  box-shadow: 0px 1px 10px rgba(112, 112, 112, 0.5);
  border-radius: ${() => TRADENAME === 'MutuaMás' ? '4px 4px 40px 4px' : '16px'};
`

const Cookies = ({ privacyUrl, setCookieTypes, initAnalytics, updateCookies, cookieAccepted, cookieTypes, modalElement: ModalElement, getCookiesApi }) => {
    const [showCookiesModal, setShowCookiesModal] = useState(!!cookieTypes)
    const [showErrorModal, setShowErrorModal] = useState(true)
    const [cookies, setCookies] = useState(cookieAccepted ? cookieTypes : null)
    const { setService } = useContext(AnalyticsContext)
    const {
      response: { result: allCookies, error: apiError },
      loading,
      sendRequest: fetchCookies,
    } = useApi(getCookiesApi)

    useEffect(() => {
      !loading && !apiError && !allCookies && fetchCookies()
    }, [loading, allCookies, apiError, fetchCookies])

    const hideModal = () => setShowErrorModal(false)

    if (apiError) {
      return <Modal isShown={showErrorModal} width='400px' onClose={hideModal}>
          <Box m='0em 0em 2em 0em'>
              <Copy fontColor='red' textAlign='center' fontWeight='medium' fontSize='medium' padding='0em 0em 2em 0em'>
                  <FormattedMessage defaultMessage='Se ha producido un error cargando la configuración de las cookies' />
              </Copy>
              <Box textAlign='center'>
                  <Button.bordered dataTest='cookie-button-error' fontColor='error' borderColor='error' onClick={hideModal}>
                      <FormattedMessage defaultMessage='Aceptar' />
                  </Button.bordered>
              </Box>
          </Box>
      </Modal>
    }

    if (!allCookies) {
      return null
    }

    if (cookies) {
        setCookieTypes(cookies)
        updateCookies(allCookies, cookies)
        if (cookies.analytic) {
            initAnalytics(() => {
              setService(window.utag)
            })
        }
        return null
    }

    return <CookiesContainer
      dataTest='cookie-banner-with-image'
      flexDirection='column'
      alignItems='center'
      width='100%'
      height='100%'
      padding={['0.5em', '0.5em', '0']}
      justifyContent={['flex-end', 'flex-end', 'center']}>
        <CookiesContent
          background='white'
          margin={['0', '0', 'auto']}
          p='2em'
          justifyContent='space-around'
          alignItems='center'
          height={['auto']}
          width={['100%', '100%', '1000px']}>
            <Box width='150px' display={['none', 'none', 'block']}>
                <Image src='/images/cookies-banner.svg' alt='App' width='120px' />
            </Box>
            <Box width={['100%', '100%', 'calc(100% - 150px)']}>
                <Box position='relative'>
                    <Copy fontSize='large' fontWeight='medium' padding='0 0 1em 0'>
                        <FormattedMessage defaultMessage='Tu privacidad es importante para nosotros' />
                    </Copy>
                    <Box>
                        <Box mb={['50px', '50px', '0']}>
                            <Copy fontSize='small' fontWeight='regular' marginBottom={['1em', '0']} padding='0'>
                                <FormattedMessage
                                  defaultMessage='Autoclub Mutua Madrileña S.L.U. utiliza cookies propias y de terceros para garantizar el buen funcionamiento de la web, y en base al análisis de tus hábitos de navegación mostrarte nuestros servicios y publicidad relacionada con tus preferencias. Más información en nuestra {link}.'
                                  values={{link: (
                                      <Copy as='a' fontSize='small' fontColor='main' href={privacyUrl} target='_blank'>
                                          <FormattedMessage defaultMessage='política de cookies'/>
                                      </Copy>
                                  )}}
                                />
                            </Copy>
                        </Box>
                    </Box>
                </Box>
                <ModalElement isShown={showCookiesModal} width='800px' showCloseButton={false} zIndex={9999}>
                    <CookiesSelector
                      defaultCookies={cookieTypes}
                      privacyUrl={privacyUrl}
                      onSave={(cookies) => {
                        setCookies(cookies)
                      }} />
                </ModalElement>
                <Flex
                  textAlign='right'
                  flexDirection={['column-reverse', 'column-reverse', 'row']}
                  justifyContent='flex-end' mt='1em'>
                    <Button.unstyled
                      dataTest='cookie-banner-with-image-configure'
                      width={['100%', '200px']}
                      height='40px'
                      disabled={loading || !allCookies}
                      transform='none'
                      onClick={() => setShowCookiesModal(true)}>
                        <Copy as='span' padding='0' fontWeight='book' transform='none' fontSize='small' fontColor='main'>
                            <FormattedMessage defaultMessage='Configurar cookies' />
                        </Copy>
                    </Button.unstyled>
                    <Box p={['0.5em 0', '0 0.5em']}>
                        <Button.bordered
                          dataTest='cookie-banner-with-image-cancel'
                          width={['100%', '300px']}
                          height='40px'
                          disabled={loading || !allCookies}
                          onClick={() => setCookies({analytic: false, advertising: false, preference: false, technical: true})}>
                            <FormattedMessage defaultMessage='Rechazar todas las cookies' />
                        </Button.bordered>
                    </Box>
                    <Button.bordered
                      dataTest='cookie-banner-with-image-submit'
                      width={['100%', '200px']}
                      height='40px'
                      disabled={loading || !allCookies}
                      onClick={() => setCookies({analytic: true, advertising: true, preference: true, technical: true})}>
                        <FormattedMessage defaultMessage='Aceptar cookies' />
                    </Button.bordered>
                </Flex>
            </Box>
        </CookiesContent>
    </CookiesContainer>
}

Cookies.propTypes = {
  privacyUrl: PropTypes.string,
  setCookieTypes: PropTypes.func,
  cookieAccepted: PropTypes.bool,
  cookieTypes: PropTypes.object,
  initAnalytics: PropTypes.func,
  updateCookies: PropTypes.func,
  modalElement: PropTypes.func,
  getCookiesApi: PropTypes.func.isRequired,
}

Cookies.defaultProps = {
  initAnalytics: () => {},
  cookieTypes: getCookieTypes(),
  cookieAccepted: getCookieAccepted(),
  modalElement: Modal,
  setCookieTypes,
  updateCookies,
  getCookiesApi: getCookies,
}

export default Cookies
